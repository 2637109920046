import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSearchBtnList,
  searchBtnListInit,
  CLocalNav,
  CLabelCard,
  CSectTitle,
  LBanquetIncs,
  LWifi,
  AssetImage,
  CBtnList,
  CNewsList,
  LBanquetContact,
} from '../../components/_index';
import '../../assets/_sass/page/banquet.scss';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const labelData = [
    {
      tagList: ['大宴会場'],
      label: '宴会棟 3F',
      img: {
        src: '/assets/images/banquet/img_banquet.png',
        alt: '',
      },
      title: <>鳳翔</>,
      text: '人数／60～1,500名',
      link: {
        href: '/banquet/grand_hall/',
      },
    },
    {
      tagList: ['中宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet02.png',
        alt: '',
      },
      title: <>芙蓉</>,
      text: '人数／40～350名',

      link: {
        href: '/banquet/fuyo/',
      },
    },
    {
      tagList: ['スカイバンケットルーム'],
      label: 'ホテル棟70F',
      img: {
        src: '/assets/images/banquet/img_banquet03.png',
        alt: '',
      },
      title: <>オーロラ／レインボー</>,
      text: '人数／50～160名',
      link: {
        href: '/banquet/sky_banquet/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet04.png',
        alt: '',
      },
      title: <>カメリア</>,
      text: '人数／20～70名',
      link: {
        href: '/banquet/camellia/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet05.png',
        alt: '',
      },
      title: <>ダリア</>,
      text: '人数／10～50名',
      link: {
        href: '/banquet/dahlia/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet06.png',
        alt: '',
      },
      title: <>アゼリア</>,
      text: '人数／10～50名',
      link: {
        href: '/banquet/azalea/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet07.png',
        alt: '',
      },
      title: <>プルミエール</>,
      text: '人数／4～14名',
      link: {
        href: '/banquet/premiere/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 4F',
      img: {
        src: '/assets/images/banquet/img_banquet08.png',
        alt: '',
      },
      title: <>さくら／けやき／いちょう／すずかけ</>,
      text: '人数／2～40名',
      link: {
        href: '/banquet/4th_floor/',
      },
    },
  ];

  const banquetCategories = [
    'すべて',
    '大宴会場',
    '中宴会場',
    'スカイバンケットルーム',
    '小宴会場',
  ];
  const banquetSearchBtnData = banquetCategories.map((item) => {
    return {
      value: item,
    };
  });
  const banquetSearchInit = searchBtnListInit<string>(
    banquetSearchBtnData[0].value
  );
  const banquetFilterData = labelData.filter((data) => {
    if (banquetSearchInit.check === 'すべて') {
      return true;
    } else {
      return data.tagList.includes(banquetSearchInit.check);
    }
  });

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'BANQUET',
            sub: '宴会・会議',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '宴会・会議',
            },
          }}
        />
      </CJumbotron>

      <section className="l_sect">
        <p className="c_sectLead u_plr25">
          <h2 className="c_headingLv2">［宴会］営業一時休止のお知らせ</h2>
          平素より横浜ロイヤルパークホテルに格別のご高配を賜り厚く御礼申しあげます。
          <br /><br />
          ビル大規模修繕工事にともない、横浜ロイヤルパークホテル宴会棟は、
          <br className="u_pc"/>
          2025年1月1日（元日）をもちまして営業を一時休止いたしました。
          <br />
          2028年以降、リニューアルオープンを予定しております。
          <br /><br />
          なお、ご宿泊は2025年3月31日（月）チェックアウトまで、
          <br className="u_pc"/>
          レストラン＆バーは2025年3月30日（日）ディナーまで営業しておりますので、
          <br />
          引き続きご愛顧賜りますよう、よろしくお願い申しあげます。
        </p>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'お知らせ', en: 'INFORMATION' }} />
          <CNewsList
            data={infoChoice(infoGet(), ['宴会・会議'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      {/* <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section> */}
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
